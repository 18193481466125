import React from "react";
import './Header.scss'
import notification from '@assets/img/notification.svg';
import vietnamFlag from '@assets/img/flags/vietnamese.png';
import englishFlag from '@assets/img/flags/english.png';
import user from '@assets/img/user.svg';
import chevDown from '@assets/img/chevron-down.svg';

const Header: React.FC = () => {
    return (
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 shadow-none border-radius-xl bg-white shadow"
        id="navbarBlur"
        navbar-scroll="true"
      >
        <div className="container-fluid py-1 px-3">
          <div
            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 d-flex justify-content-between align-items-center"
            id="navbar"
          >
            <ul className="navbar-nav gap-4 ms-auto header-group">
              <li className="nav-item">
                <a className="nav-link">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link">About Us</a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#" key="">
                      Talent Sourcing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Coming soon
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Coming soon
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Coming soon
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="">
                  Contact
                </a>
              </li>
            </ul>
            <div className="user-group ms-auto me-3 d-flex align-items-center gap-3">
              <div className="notification-group">
                <button className="btn-header">
                  <img src={notification} alt="" />
                </button>
              </div>
              <div className="language-group">
                <div className="dropdown">
                  <button
                    className="border-0 p-2 bg-transparent dropdown-toggle dropdown-language"
                    type="button"
                    data-bs-toggle="dropdown"
                    style={{ borderRadius: "10px" }}
                  >
                    <img id="language_src" src="" width="23" />
                  </button>
                  <ul className="dropdown-menu dropdown-language-box">
                    <li className="mb-1">
                      <a className="dropdown-item d-flex align-items-center gap-2">
                        <img src={vietnamFlag} width="20" />
                        <span>Vietnamese</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item d-flex align-items-center gap-2">
                        <img src={englishFlag} width="20" />
                        <span>English</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="user-group">
                <div className="d-flex align-items-center gap-2">
                  <div className="flex-shrink-0">
                    <div className="user-avatar rounded-circle">
                      <img
                        width="35"
                        src={`https://thumbs.dreamstime.com/b/generative-ai-young-smiling-man-avatar-man-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-d-vector-people-279560903.jpg`}
                        className="rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="user-name fw-bold">User name</div>
                    <div className="user-role">Free trial</div>
                  </div>
                  <div className="flex-grow-1">
                    <img src={chevDown} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
}

export default Header;