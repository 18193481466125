import React from "react"
import outro from '@assets/img/HEPPAIoutro.png'
import './Outro.scss'

const Outro: React.FC = () => {
    return (
      <div className="outro-section mt-5 pe-5 ps-5 pt-5 d-flex flex-column align-items-center">
        <div className="outro-title">The Future of Work is Here</div>
        <div className="outro-subtitle">
          Experience the transformative power of automation
        </div>
        <button className="start-journey py-2 px-5">Start Your Journey</button>
        <img className="outro-image mt-5" src={outro} />
      </div>
    );
}

export default Outro;