import React, { useEffect, useRef, useState } from 'react';
import { Modal as AntModal } from 'antd';
import { Input } from 'antd';
import type { GetProps } from 'antd';
import './VerifyModal.scss'

interface IModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void
}

const VerifyModal: React.FC<IModalProps> = ({ isModalOpen, setIsModalOpen }) => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        if (isModalOpen) {
            setTimeout(() => {
                inputRefs.current[0]?.focus();
            }, 200); // Thời gian trễ có thể điều chỉnh (100ms là thường đủ)
        }
    }, [isModalOpen]);

    const handleChange = (element: any, index: any): any => {
        const value = element.value;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < 5) {
                inputRefs.current[index + 1]?.focus();
            }
        }

    };

    const handleKeyDown = (e: any, index: any) => {
        if (e.key === "Backspace") {
            const newOtp = [...otp];
            otp.map((item, index) => {
                newOtp[index] = ''; 
            })
            setOtp(newOtp)
            inputRefs.current[0]?.focus();
        }
    };

    return (
        <AntModal
            className='VerifyModal'
            open={isModalOpen}
            footer={false}
            closable={false}
            destroyOnClose={true}
        >
            <div className="otp-container text-center">
                <h2 className="mb-2">Email Verification</h2>
                <p>Enter the 6-digit verification code that was sent to
                    your email.</p>
                <div className="otp-inputs mb-4 mt-3 w-100">
                    {otp.map((value, index) => (
                        <input
                            className='otp-input'
                            autoFocus={true}
                            key={index}
                            type="text"
                            maxLength={1}
                            value={value}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                        />
                    ))}
                </div>
                <p className='mb-2'>Didn't receive code? <a href="#"
                    id="resend-link">Resend</a></p>
            </div>
        </AntModal>
    );
};

export default VerifyModal;