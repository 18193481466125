import React from 'react'
import Header from '@components/Recruiter/Header/Header';
import SideBar from '@components/Recruiter/SideBar/SideBar';
import TalentSourcing from '@containers/Recruiter/TalentSourcing/TalentSourcing';
import { Outlet } from 'react-router-dom';

const Recruiter = () => {
    return (
        <div className="g-sidenav-show bg-gray-100 d-flex">
            <SideBar />
            <main className="flex-fill main-content position-relative max-height-vh-100 border-radius-lg">
                <Header />
                <div className="container-fluid p-3">
                    <Outlet />
                </div>
            </main>
        </div>
    )
}

export default Recruiter;
