import React from "react";
import Lottie from "lottie-react";
import HeroImage from "@assets/img/Radar.json";
import "./Hero.scss";
import { url } from "inspector";

const Hero: React.FC = () => {
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Lottie
          animationData={HeroImage}
          loop={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
        />
      </div>
      <div className="hero-section">
        <div className="container-slogan">
          <div className="slogan-title">
            Streamline Sourcing, Empowering Growth, Unlock Full Potentials
          </div>
          <p className="mb-0">
            Transforming potential into performance through innovative career
            development, seamless recruitment, and strategic branding solutions.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;
