import React from "react";
import addBtn from "@assets/img/add.svg";

interface TLocationFilter {
  keywords: any;
  handleCheckboxChange: (index: any, type: any) => void;
  showLocationInput: boolean;
  setShowLocationInput: (value: boolean) => void;
  tempLocation: string;
  setTempLocation: (value: string) => void;
  locationSuggestions: boolean;
  setLocationSuggestion: (valus: boolean) => void;
  onInputSuggestion: (value: string) => void;
  locationList: any[];
  handleAddKeyword: (e: any, type: string, name: string) => void;
}

const LocationFilter: React.FC<TLocationFilter> = ({
  keywords,
  handleCheckboxChange,
  showLocationInput,
  setShowLocationInput,
  tempLocation,
  setTempLocation,
  locationSuggestions,
  setLocationSuggestion,
  onInputSuggestion,
  locationList,
  handleAddKeyword,
}) => {
  return (
    <div className="location-filter mb-2">
      <div className="filter-label">Locations</div>
      <div className="form-filter" id="locationCheckboxes">
        {keywords.locations.length > 0 &&
          keywords.locations.map((item: any, index: any) => {
            return (
              <div className="form-check-item" key={index}>
                <div className="form-check m-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={item.title}
                    onChange={() => handleCheckboxChange(index, "location")}
                    checked={!!item.value}
                  />
                  <label className="form-check-label">{item.title}</label>
                </div>
              </div>
            );
          })}
        {!showLocationInput && (
          <div className="form-add" onClick={() => setShowLocationInput(true)}>
            <img src={addBtn} />
            <span>Add</span>
          </div>
        )}
        <div className={`form-input ${showLocationInput ? `show` : ""}`}>
          <input
            type="text"
            className={`location-input ${showLocationInput ? `show` : ""}`}
            placeholder="Enter Location"
            value={tempLocation}
            onChange={(e) => setTempLocation(e.target.value)}
            onInput={() => onInputSuggestion("location")}
          />
          <button
            className="btn-close small"
            onClick={() => setShowLocationInput(false)}
          ></button>
          <div
            className={`suggestions location-suggestion ${
              locationSuggestions ? "show" : ""
            }`}
            onBlur={() => setLocationSuggestion(false)}
          >
            {locationList.map((item, index) => {
              const locationName = Object.values(item)[0] as string;
              return (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={(e) => handleAddKeyword(e, "location", locationName)}
                >
                  {locationName}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationFilter;
