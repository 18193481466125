import React from "react"
import './LoginForm.scss'

const LoginForm: React.FC = () => {
    return (
        <div className="hero-static col-lg-8 d-flex flex-column align-items-center bg-body-light">
            <div className="p-3 w-100 d-lg-none text-center">
                <a className="link-fx fw-semibold fs-3 text-dark" href="/">
                    HEPP<span className="fw-normal">AI</span>
                </a>
            </div>
            <div className="p-4 w-100 flex-grow-1 d-flex align-items-center">
                <div className="w-100">
                    <div className="text-center mb-5">
                        <p className="mb-3">
                            <i className="fa fa-2x fa-circle-notch login-logo"></i>
                        </p>
                        <h1 className="fw-bold mb-2">
                            Sign In
                        </h1>
                        <p className="fw-medium text-muted">
                            Welcome, please login or <a href="/register"
                                className="signup-redirect">sign up</a> for a new account.
                        </p>
                    </div>

                    <div className="row g-0 justify-content-center">
                        <div className="col-sm-8 col-xl-4">
                            <div className="mb-4">
                                <input type="text"
                                    className="form-control form-control-lg form-control-alt py-3"
                                    id="login-email" name="login-email"
                                    placeholder="Email" />
                            </div>
                            <div className="mb-4">
                                <input type="password"
                                    className="form-control form-control-lg form-control-alt py-3"
                                    id="login-password" name="login-password"
                                    placeholder="Password" />
                            </div>
                            <div
                                className="d-flex justify-content-between align-items-center mb-4">
                                <div>
                                    <a
                                        className="text-muted fs-sm fw-medium d-block d-lg-inline-block mb-1"
                                        href="op_auth_reminder3.html">
                                        Forgot Password?
                                    </a>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-lg" id="signin-btn">
                                        <i
                                            className="fa fa-fw fa-sign-in-alt me-1 opacity-50"></i>
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="px-4 py-3 w-100 d-lg-none d-flex flex-column flex-sm-row justify-content-between fs-sm text-center text-sm-start">
                <p className="fw-medium text-black-50 py-2 mb-0">
                    <strong>HEPPAI 0.0.1</strong> &copy; <span
                        data-toggle="year-copy"></span>
                </p>
                <ul className="list list-inline py-2 mb-0">
                    <li className="list-inline-item">
                        <a className="text-muted fw-medium"
                            href="javascript:void(0)">Legal</a>
                    </li>
                    <li className="list-inline-item">
                        <a className="text-muted fw-medium"
                            href="javascript:void(0)">Contact</a>
                    </li>
                    <li className="list-inline-item">
                        <a className="text-muted fw-medium"
                            href="javascript:void(0)">Terms</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default LoginForm