import React from "react";
import Header from "@components/Home/Header/Header";
import Footer from "@components/Home/Footer/Footer";
import { Outlet } from "react-router-dom";
import "@assets/bootstraps/css/bootstrap.css";
import "@assets/css/Home.scss";

const Home: React.FC<any> = ({ isLanding }) => {
  return (
    <div id="body">
      <Header />
      <Outlet />
      <Footer isLanding={isLanding} />
    </div>
  );
};

export default Home;
