
interface IRegisterForm {
    setIsVerifyModalVisible?: (isOpen: boolean) => void;
}

const RegisterForm: React.FC<IRegisterForm> = ({ setIsVerifyModalVisible }) => {

    const openVerifyModal = () => {
        setIsVerifyModalVisible?.(true)
    }

    return (
        <div
            className="hero-static col-lg-8 d-flex flex-column align-items-center bg-body-light">
            <div className="p-3 w-100 d-lg-none text-center">
                <a className="link-fx fw-semibold fs-3 text-dark"
                    href="/">
                    HEPP<span className="fw-normal">AI</span>
                </a>
            </div>
            <div
                className="p-4 w-100 flex-grow-1 d-flex align-items-center">
                <div className="w-100">
                    <div className="text-center mb-5">
                        <p className="mb-3">
                            <i
                                className="fa fa-2x fa-circle-notch login-logo"></i>
                        </p>
                        <h1 className="fw-bold mb-2">
                            Create Account
                        </h1>
                        <p className="fw-medium text-muted">
                            Get your access today in one easy
                            step
                        </p>
                    </div>
                    <div className="row g-0 justify-content-center">
                        <div className="col-sm-8 col-xl-4">
                            <div className="mb-4">
                                <input type="text"
                                    className="form-control form-control-lg form-control-alt py-3 register-field"
                                    id="signup-full-name"
                                    name="signup-full-name"
                                    placeholder="Full name" />
                            </div>
                            <div className="mb-4">
                                <input type="text"
                                    className="form-control form-control-lg form-control-alt py-3 register-field"
                                    id="signup-phone"
                                    name="signup-phone"
                                    placeholder="Phone" />
                            </div>
                            <div className="mb-4">
                                <input type="email"
                                    className="form-control form-control-lg form-control-alt py-3 register-field"
                                    id="signup-email"
                                    name="signup-email"
                                    placeholder="Email" />
                            </div>
                            <div className="mb-4">
                                <input type="password"
                                    className="form-control form-control-lg form-control-alt py-3 register-field"
                                    id="signup-password"
                                    name="signup-password"
                                    placeholder="Password" />
                            </div>
                            <div className="mb-4">
                                <input type="password"
                                    className="form-control form-control-lg form-control-alt py-3 register-field"
                                    id="signup-password-confirm"
                                    name="signup-password-confirm"
                                    placeholder="Confirm Password" />
                            </div>
                            <div className="mb-4">
                                <div
                                    className="d-md-flex align-items-md-center justify-content-md-between">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="signup-terms"
                                            name="signup-terms" />
                                        <label className="form-check-label">
                                            I agree to Terms &amp; Conditions
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button
                                    onClick={openVerifyModal}
                                    className="btn btn-lg" id="signup-btn">
                                    <i
                                        className="fa fa-fw fa-plus me-1 opacity-50"></i>
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="px-4 py-3 w-100 d-lg-none d-flex flex-column flex-sm-row justify-content-between fs-sm text-center text-sm-start">
                <p className="fw-medium text-black-50 py-2 mb-0">
                    <strong>HEPPAI 0.0.1</strong> &copy; <span
                        data-toggle="year-copy"></span>
                </p>
                <ul className="list list-inline py-2 mb-0">
                    <li className="list-inline-item">
                        <a className="text-muted fw-medium"
                            href="javascript:void(0)">Legal</a>
                    </li>
                    <li className="list-inline-item">
                        <a className="text-muted fw-medium"
                            href="javascript:void(0)">Contact</a>
                    </li>
                    <li className="list-inline-item">
                        <a className="text-muted fw-medium"
                            href="javascript:void(0)">Terms</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default RegisterForm;