import { useState } from "react";
import BgCover from "@components/Auth/BgCover/BgCover";
import RegisterForm from "@components/Auth/RegisterForm/RegisterForm";
import VerifyModal from "@components/Auth/VerifyModal/VerifyModal";
import './SignUp.scss'

const SignUp: React.FC = () => {
    const [isVerifyModelVisible, setIsVerifyModalVisible] = useState(false)

    return (
        <div className="bg-image bg-cover-left">
            <div className="row g-0 bg-primary-dark-op">
                <BgCover />
                <RegisterForm setIsVerifyModalVisible={setIsVerifyModalVisible} />
                <VerifyModal isModalOpen={isVerifyModelVisible} setIsModalOpen={setIsVerifyModalVisible} />
            </div>
        </div>
    )
}

export default SignUp;