import React from 'react'
import Header from '@components/Talent/Header/Header';
import Footer from '@components/Home/Footer/Footer';
import Filter from '@components/Talent/Filter/Filter';
import JobHub from '@containers/Talent/JobHub/JobHub';

const Talent: React.FC = () => {
    return (
        <main className="flex-fill main-content position-relative max-height-vh-100 border-radius-lg">
            <Header />
            <Filter />
            <div className="container px-5 py-3 mb-5">
                <JobHub />
            </div>
            <Footer />
        </main>
    )
}

export default Talent;