import React from "react";
import addBtn from "@assets/img/add.svg";

interface TIndustryFilter {
  keywords: any;
  handleCheckboxChange: (index: any, type: any) => void;
  showIndustryInput: boolean;
  setShowIndustryInput: (value: boolean) => void;
  tempIndustry: string;
  setTempIndustry: (value: string) => void;
  industrySuggestion: boolean;
  setIndustrySuggestion: (value: boolean) => void;
  onInputSuggestion: (value: string) => void;
  industryList: any[];
  handleAddKeyword: (e: any, type: string, name: string) => void;
}

const IndustryFilter: React.FC<TIndustryFilter> = ({
  keywords,
  handleCheckboxChange,
  showIndustryInput,
  setShowIndustryInput,
  tempIndustry,
  setTempIndustry,
  onInputSuggestion,
  industrySuggestion,
  setIndustrySuggestion,
  industryList,
  handleAddKeyword,
}) => {
  return (
    <div className="domain-filter mb-2">
      <div className="filter-label">Industries</div>
      <div className="form-filter" id="industryCheckboxes">
        {keywords.industries.length > 0 &&
          keywords.industries.map((item: any, index: any) => {
            return (
              <div className="form-check-item" key={index}>
                <div className="form-check m-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={item.title}
                    onChange={() => handleCheckboxChange(index, "industry")}
                    checked={!!item.value}
                  />
                  <label className="form-check-label">{item.title}</label>
                </div>
              </div>
            );
          })}
        {!showIndustryInput && (
          <div className="form-add" onClick={() => setShowIndustryInput(true)}>
            <img src={addBtn} />
            <span>Add</span>
          </div>
        )}
        <div className={`form-input ${showIndustryInput ? `show` : ""}`}>
          <input
            type="text"
            className={`industry-input ${showIndustryInput ? `show` : ""}`}
            value={tempIndustry}
            placeholder="Enter Industry"
            onChange={(e) => setTempIndustry(e.target.value)}
            onInput={() => onInputSuggestion("industry")}
          />
          <button
            className="btn-close"
            onClick={() => setShowIndustryInput(false)}
          ></button>
          <div
            className={`suggestions industry-suggestion ${
              industrySuggestion ? "show" : ""
            }`}
          >
            {industryList.map((item, index) => {
              const industryName = Object.values(item)[0] as string;
              return (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={(e) => handleAddKeyword(e, "industry", industryName)}
                >
                  {industryName}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryFilter;
