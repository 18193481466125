import React from "react";
import './JobHub.scss'

const JobHub: React.FC = () => {
    return (
        <div className="row">
            <div className="col col-lg-5 col-sm-12">
                <div className="job-list">
                    <div className="card-header">
                        <span className="fw-bold">685</span> new jobs
                    </div>
                    <div className="job-body">
                        <div className="job-item selected">
                            <div className="job-info d-flex gap-3">
                                <div className="flex-shrink-0">
                                    <img width="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKk8Nc-lBHyDwEMs0drgzArhbsx4Ihq-_DIA&s" alt="" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="job-title">Python Management</div>
                                    <div className="job-company">Suleco Company</div>
                                    <div className="job-salary">Sign in to view Salary</div>
                                    <div className="job-location">Ho Chi Minh • 2 hours ago</div>
                                </div>
                            </div>
                            <div className="position-related d-flex gap-2 mt-1">
                                <div className="position-item">Communication Skills</div>
                                <div className="position-item">Problem Solving</div>
                                <div className="position-item">Time Management</div>
                            </div>
                        </div>

                        <div className="job-item">
                            <div className="job-info d-flex gap-3">
                                <div className="flex-shrink-0">
                                    <img width="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKk8Nc-lBHyDwEMs0drgzArhbsx4Ihq-_DIA&s" alt="" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="job-title">Growth Marketing Manager</div>
                                    <div className="job-company">Automate Solutions</div>
                                    <div className="job-salary">Sign in to view Salary</div>
                                    <div className="job-location">Ha Noi • 4 days ago</div>
                                </div>
                            </div>
                            <div className="position-related d-flex gap-2 mt-1">
                                <div className="position-item">Marketing Strategy</div>
                            </div>
                        </div>

                        <div className="job-item">
                            <div className="job-info d-flex gap-3">
                                <div className="flex-shrink-0">
                                    <img width="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKk8Nc-lBHyDwEMs0drgzArhbsx4Ihq-_DIA&s" alt="" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="job-title">Senior Software Engineer</div>
                                    <div className="job-company">freeC's client</div>
                                    <div className="job-salary">Sign in to view Salary</div>
                                    <div className="job-location">Ho Chi Minh • 1 days ago</div>
                                </div>
                            </div>
                            <div className="position-related d-flex gap-2 mt-1">
                                <div className="position-item">Marketing Strategy</div>
                            </div>
                        </div>

                        <div className="job-item">
                            <div className="job-info d-flex gap-3">
                                <div className="flex-shrink-0">
                                    <img width="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKk8Nc-lBHyDwEMs0drgzArhbsx4Ihq-_DIA&s" alt="" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="job-title">Flutter Developer</div>
                                    <div className="job-company">freeC's client</div>
                                    <div className="job-salary">Sign in to view Salary</div>
                                    <div className="job-location">Da Nang • 1 days ago</div>
                                </div>
                            </div>
                            <div className="position-related d-flex gap-2 mt-1">
                                <div className="position-item">Flutter</div>
                                <div className="position-item">Mobile</div>
                                <div className="position-item">UI/UX</div>
                            </div>
                        </div>

                        <div className="job-item">
                            <div className="job-info d-flex gap-3">
                                <div className="flex-shrink-0">
                                    <img width="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKk8Nc-lBHyDwEMs0drgzArhbsx4Ihq-_DIA&s" alt="" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="job-title">PHP Developer</div>
                                    <div className="job-company">freeC's client</div>
                                    <div className="job-salary">Sign in to view Salary</div>
                                    <div className="job-location">Ho Chi Minh • 1 days ago</div>
                                </div>
                            </div>
                            <div className="position-related d-flex gap-2 mt-1">
                                <div className="position-item">PHP</div>
                                <div className="position-item">Laravel</div>
                                <div className="position-item">CodeIgter</div>
                            </div>
                        </div>

                        <div className="job-item">
                            <div className="job-info d-flex gap-3">
                                <div className="flex-shrink-0">
                                    <img width="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKk8Nc-lBHyDwEMs0drgzArhbsx4Ihq-_DIA&s" alt="" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="job-title">Frontend Developer</div>
                                    <div className="job-company">freeC's client</div>
                                    <div className="job-salary">Sign in to view Salary</div>
                                    <div className="job-location">Da Nang • 1 days ago</div>
                                </div>
                            </div>
                            <div className="position-related d-flex gap-2 mt-1">
                                <div className="position-item">ReactJs</div>
                                <div className="position-item">Angular</div>
                            </div>
                        </div>

                        <div className="job-item">
                            <div className="job-info d-flex gap-3">
                                <div className="flex-shrink-0">
                                    <img width="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKk8Nc-lBHyDwEMs0drgzArhbsx4Ihq-_DIA&s" alt="" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="job-title">Backend Developer</div>
                                    <div className="job-company">freeC's client</div>
                                    <div className="job-salary">Sign in to view Salary</div>
                                    <div className="job-location">Ho Chi Minh • 1 days ago</div>
                                </div>
                            </div>
                            <div className="position-related d-flex gap-2 mt-1">
                                <div className="position-item">Python</div>
                                <div className="position-item">Java</div>
                                <div className="position-item">C#</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col col-lg-7 col-sm-none">
                <div className="card job-detail p-3">
                    <div className="d-flex mb-3 align-items-center justify-content-between">
                        <div>
                            <div className="job-detail-title">Python Frontend Developer</div>
                            <div className="job-detail-company">Công ty CP Công nghệ Mobiwork</div>
                        </div>
                        <div className="d-flex gap-2">
                            <button className="btn-like d-flex align-items-center">
                                <span className="material-symbols-outlined">
                                    favorite
                                </span>
                            </button>
                            <button className="btn-apply">Apply</button>
                        </div>
                    </div>
                    <div className="job-detail-overall">
                        <div className="job-detail-salary mb-1">
                            <span className="material-symbols-outlined">
                                attach_money
                            </span>
                            Sign in to view salary
                        </div>
                        <div className="job-detail-timeworking mb-1">
                            <span className="material-symbols-outlined">
                                bolt
                            </span>
                            Full time
                        </div>
                        <div className="job-detail-hiring mb-1">
                            <span className="material-symbols-outlined">
                                group
                            </span>
                            Hiring 1 people
                        </div>
                        <div className="job-detail-exp-request mb-1">
                            <span className="material-symbols-outlined">
                                business_center
                            </span>
                            2 years of experience
                        </div>
                        <div className="job-detail-location mb-1">
                            <span className="material-symbols-outlined">
                                location_on
                            </span>
                            Ha Noi
                        </div>
                        <div className="job-detail-published-date mb-1">
                            <span className="material-symbols-outlined">
                                schedule
                            </span>
                            Published 07/08/2024
                        </div>
                    </div>
                    <hr />
                    <div className="job-detail-description">
                        <div className="description-title">Why You'll Love Working Here</div>
                        <ul className="why-choosing">
                            <li className="description-item">Công ty đóng 100% BHYT, BHXH, BHTN (không trừ vào lương)</li>
                            <li className="description-item">Công ty cung cấp thiết bị làm việc</li>
                            <li className="description-item">Thưởng ngày lễ 2/3, 30/4, 1,5</li>
                        </ul>
                        <div className="description-title">Working Address</div>
                        <ul className="working-address">
                            <li className="description-item">Khu VP tầng 3, Toà nhà Vietphone, 21 Phan Kế Bính, phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh</li>
                        </ul>
                        <div className="description-title">Job Description</div>
                        <ul className="job-description">
                            <li className="description-item">Lập trình phát triển, nâng cấp và sửa lỗi các ứng dụng trên web</li>
                            <li className="description-item">Tham gia vào dự án xây dựng các module mới và nâng cấp, hiệu suất module có sẵn của ứng dụng ERP mã nguồn mở</li>
                            <li className="description-item">Được đào tạo trong 2 tuần về nền tảng framework của ứng dung ERP mã nguồn mở</li>
                            <li className="description-item">Các công việc khác theo sự phần công của quản lý</li>
                        </ul>
                        <div className="description-title">Job Requirement</div>
                        <ul className="job-requirement">
                            <li className="description-item">Graduated with Bachelor degree of Electrical Engineer/ Computer Science</li>
                            <li className="description-item">Have min 3+ years of experience as a Physical Design/ Chip Design</li>
                            <li className="description-item">Familiar with Cadence Innovus or Synopsys ICC2/ Fusion Compiler;</li>
                            <li className="description-item">English: Intermdiate level & above (TOEIC 730-855 is preferred;) to work with foreign Technical Manager and teammates</li>
                            <li className="description-item">Have experience in 65/40/28 nm IC design experiences will be plus;</li>
                        </ul>
                        <div className="description-title">Benefits</div>
                        <ul className="job-benefit">
                            <li className="description-item">Bonus: 13th month of fixed bonus</li>
                            <li className="description-item">Incentive/performance bonus: 14th, 15th month salary (based on performance)</li>
                            <li className="description-item">Annual leave: 15 days/ year + 3 sick-leave</li>
                            <li className="description-item">Allowance: transportation allowance (1,5mil) + meal allowance (1,5mil)</li>
                            <li className="description-item">Insurance according to the Labor law based of full salary</li>
                            <li className="description-item">Probation: 2 months with 100% salary + PVI Healthcare insurance for the employee</li>
                            <li className="description-item">PVI Healthcare insurance for the employee + family members</li>
                            <li className="description-item">Annual company trip</li>
                            <li className="description-item">Annual salary review (each March)</li>
                            <li className="description-item">Year-end parties, birthday gifts,...</li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobHub;