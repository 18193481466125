import React from "react";
import "./SideBar.scss";
import searchIcon from "@assets/img/search-normal.svg";
import { Badge } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideBar: React.FC = () => {
  const recruiterPath = {
    dashboard: "/recruiter/panel/dashboard",
    talentSourcing: "/recruiter/panel",
    job: "/recruiter/panel/job",
    setting: "/recruiter/panel/setting",
  };

  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 bg-white Sidebar"
      style={{ zIndex: 10, width: "280px" }}
    >
      <a
        href="/"
        className="d-flex align-items-center mb-3 mb-md-0 justify-content-center text-decoration-none"
      >
        <span className="sidebar-title text-secondary">
          HEPP<span className="text-heppai-primary">AI</span>
        </span>
      </a>
      <ul className="nav nav-pills recruiter-menu flex-column mb-auto pt-3">
        <li className="nav-item mb-3">
          <div className="input-container">
            <span className="prefix">
              <img width="20" src={searchIcon} alt="" />
            </span>
            <input
              type="text"
              className="form-control-search"
              placeholder="Search"
            />
          </div>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link nav-link-sidebar link-dark ${
              useLocation().pathname == recruiterPath.dashboard ? "active" : ""
            }`}
            to={recruiterPath.dashboard}
          >
            <span className="material-symbols-outlined">bar_chart_4_bars</span>
            <span>Dashboard</span>
            <Badge className="ms-auto" count={`Coming soon`} color="#cecece" />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link nav-link-sidebar link-dark ${
              useLocation().pathname == recruiterPath.talentSourcing
                ? "active"
                : ""
            }`}
            to={"/recruiter/panel"}
          >
            <span className="material-symbols-outlined">person_search</span>
            <span>Talent Sourcing</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link nav-link-sidebar link-dark ${
              useLocation().pathname == recruiterPath.job ? "active" : ""
            }`}
            to={"/recruiter/panel/job"}
          >
            <span className="material-symbols-outlined">work</span>
            <span>Job Posts</span>
            <Badge className="ms-auto" count={`Coming soon`} color="#cecece" />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link nav-link-sidebar link-dark ${
              useLocation().pathname == recruiterPath.setting ? "active" : ""
            }`}
            to={"/recruiter/panel/setting"}
          >
            <span className="material-symbols-outlined">settings</span>
            <span>Setting</span>
            <Badge className="ms-auto" count={`Coming soon`} color="#cecece" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
