import React from 'react'
import SignUp from '@containers/Auth/SignUp/SignUp';

const Register: React.FC = () => {
    return (
        <>
            <div id="page-container">
                <main id="main-container">
                    <SignUp />
                </main>
            </div>
            <div id="page-loader"></div>
        </>
    )
}

export default Register;