import React, { useEffect } from 'react'
import SignIn from '@containers/Auth/SignIn/SignIn';
import '@assets/css/oneui.scss'

const Login: React.FC = () => {
    return (
        <>
            <div id="page-container">
                <main id="main-container">
                    <SignIn />
                </main>
            </div>
            <div id="page-loader"></div>
        </>
    )
}

export default Login;