import React from "react"

const BgCover: React.FC = () => {
    return(
        <div
            className="hero-static col-lg-4 d-none d-lg-flex flex-column justify-content-center">
            <div className="p-4 p-xl-5 flex-grow-1 d-flex align-items-center">
                <div className="w-100">
                    <a className="link-fx fw-semibold fs-2 text-white"
                        href="index.html">
                        HEPP<span className="fw-normal">AI</span>
                    </a>
                    <p className="text-white-75 me-xl-8 mt-2">
                        Welcome to your amazing app. Feel free to login and start
                        managing your projects and clients.
                    </p>
                </div>
            </div>
            <div
                className="p-4 p-xl-5 d-xl-flex justify-content-between align-items-center fs-sm">
                <p className="fw-medium text-white-50 mb-0">
                    <strong>OneUI 0.0.1</strong> &copy; <span
                        data-toggle="year-copy"></span>
                </p>
                <ul className="list list-inline mb-0 py-2">
                    <li className="list-inline-item">
                        <a className="text-white-75 fw-medium"
                            href="javascript:void(0)">Legal</a>
                    </li>
                    <li className="list-inline-item">
                        <a className="text-white-75 fw-medium"
                            href="javascript:void(0)">Contact</a>
                    </li>
                    <li className="list-inline-item">
                        <a className="text-white-75 fw-medium"
                            href="javascript:void(0)">Terms</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default BgCover;