import React from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Admin from "./layouts/Admin";
import Employer from "./layouts/Employer";
import Home from "./layouts/Home";
import Recruiter from "./layouts/Recruiter";
import Login from "./layouts/Login";
import Register from "./layouts/Register";
import Talent from "./layouts/Talent";
import RecruiterPage from "@containers/Home/RecruiterPage/RecruiterPage";
import LandingPage from "@containers/Home/LandingPage/LandingPage";
import TalentSourcing from "@containers/Recruiter/TalentSourcing/TalentSourcing";
import ComingSoon from "@containers/ComingSoon/ComingSoon";

interface ProtectedRouteProps {
  isAuthenticated: boolean;
  hasTalentPackage?: boolean;
  hasRecruiterPackage?: boolean;
  hasEmployerPackage?: boolean;
  isAdmin?: boolean;
  children?: React.ReactNode;
}

const isAuthenticated = true;
const hasTalentPackage = true;
const hasRecruiterPackage = true;
const hasEmployerPackage = false;
const isAdmin = false;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAuthenticated,
  hasTalentPackage,
  hasRecruiterPackage,
  hasEmployerPackage,
  isAdmin,
  children
}) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (
    !hasTalentPackage &&
    !hasRecruiterPackage &&
    !hasEmployerPackage &&
    !isAdmin
  ) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export const HeppaiRoute = () => {
  return (
    <Routes>
      {/* HOME ROUTER */}
      <Route
        path="/"
        element={
          <Home isLanding={useLocation().pathname == "/" ? true : false} />
        }
      >
        <Route index element={<LandingPage />} />
        <Route path="recruiter" element={<RecruiterPage />} />
      </Route>

      {/* AUTHENTICATE ROUTER */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />

      {/* TALENT ROUTER */}
      <Route
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            hasTalentPackage={hasTalentPackage}
          />
        }
      >
        <Route path="/talent" element={<Talent />} />
      </Route>

      {/* RECRUITER ROUTER */}
      <Route
        path="/recruiter/panel"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            hasRecruiterPackage={hasRecruiterPackage}
          >
            <Recruiter />
          </ProtectedRoute>
        }
      >
        <Route index element={<TalentSourcing />} />
        <Route path="dashboard" element={<ComingSoon />} />
        <Route path="job" element={<ComingSoon />} />
        <Route path="setting" element={<ComingSoon />} />
      </Route>

      {/* EMPLOYER ROUTER */}
      <Route
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            hasEmployerPackage={hasEmployerPackage}
          />
        }
      >
        <Route path="/employer" element={<Employer />} />
      </Route>

      {/* ADMIN ROUTER */}
      <Route
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
        }
      >
        <Route path="/admin" element={<Admin />} />
      </Route>
    </Routes>
  );
};

export default ProtectedRoute;
