import React from "react";
import './Filter.scss'


const Filter: React.FC = () => {
    return (
        <div className={`filter-wrap bg-white w-100`}>
            <div className="container px-5 py-3 filter-group">
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="input-group">
                            <input className="position-input" placeholder="Search for job" type="text" />
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="input-group">
                            <input className="location-input" placeholder="Ho Chi Minh City" type="text" />
                            <span className="material-symbols-outlined">location_on</span>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12">
                        <button className="btn-search">Search</button>
                    </div>
                </div>
                <div className="filter-dropdown-group mt-3">
                    <div className="d-flex align-items-center gap-5">
                        <div className="filter-item dropdown">
                            <div className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Date posted</div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                        <div className="filter-item dropdown">
                            <div className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Career</div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                        <div className="filter-item dropdown">
                            <div className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Salary</div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                        <div className="filter-item dropdown">
                            <div className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Employment type</div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                        <div className="filter-item dropdown">
                            <div className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Level</div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                        <div className="filter-item dropdown">
                            <div className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Benefit</div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter;