import React, { useEffect, useState } from "react";
import "./Result.scss";
import pusher from "@utils/Pusher";
import axios from "axios";
import LinkedInLogo from "@assets/img/linkedIn.svg";
import { Empty, message, Skeleton } from "antd";
import { businessPayload, notification } from "@utils/Helpers";
import ScatterChart from "@components/Recruiter/ScatterChart/ScatterChart";
import CandidateDetail from "./CandidateDetail/CandidateDetail";

interface TResult {
  visible?: boolean;
  setCurrentStep?: (step: number) => void;
  pusherEventCrawl: string;
  pusherChannelCrawl: string;
  jobDescription: string;
}

interface TCandidate {
  name: string;
  position: string;
  avatar: string;
  slug: string;
  overall: number;
  skills: number;
  experiences: number;
  languages: number;
  education: number;
}

const Result: React.FC<TResult> = ({
  visible,
  setCurrentStep,
  pusherChannelCrawl,
  pusherEventCrawl,
  jobDescription,
}) => {
  const [linkedinUrlData, setLinkedinUrlData] = useState([]);
  const [linkedinProfileData, setLinkedinProfileData] = useState([]);
  const [linkedinEvaluateData, setLinkedinEvaluateData] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState<TCandidate>();
  const [isCandidateDetailModal, setIsCandidateDetailModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState<string>("");

  useEffect(() => {
    if (visible) {
      console.log("Reload page");
      const data = {
        "pusher-channel-name": pusherChannelCrawl,
        "pusher-event-name": pusherEventCrawl,
      };
      pusher(data, (message) => {
        console.log("Pusher 110: ", message);
        setLinkedinUrlData(message);
        getPusherInfoForCrawlProfile(message);
      });
    }
  }, [visible]);

  const getPusherInfoForCrawlProfile = async (message: any) => {
    axios
      .post(
        process.env.REACT_APP_BUSINESS_LOGIC_ENDPOINT + "/task/execution",
        businessPayload.script(210, { candidates: message })
      )
      .then((response) => {
        if (response.status == 200) {
          pusher(response.data, (message: any) => {
            console.log("Pusher 210: ", message);
            if (!message.status) {
              setLinkedinProfileData((prevData): any => [...prevData, message]);
            }
          });
        }
      })
      .catch((error) => {
        notification.error("Something went wrong in server");
      });
  };

  useEffect(() => {
    if (
      linkedinProfileData.length > 0 &&
      linkedinProfileData.length == linkedinUrlData.length
    ) {
      crawlEvaluate();
    }
  }, [linkedinProfileData]);

  const payloadFormat = (): any[] => {
    const payload: any[] = [];

    linkedinProfileData.map((item: any) => {
      const payloadItem = {
        full_name: item.details.name,
        url: item.url,
        position: item.details.position || "N/A",
        education: item.details.crawl_profile_educations
          ? item.details.crawl_profile_educations.map((edu: any) => ({
              school: edu.school_name,
              degree_type: edu.degree_type,
              period: edu.time_period,
            }))
          : [],
        experience: item.details.crawl_profile_exps
          ? item.details.crawl_profile_exps.map((exp: any) => ({
              company: exp.company_name,
              title: exp.job_title,
              period: exp.time_period,
              description: null,
            }))
          : [],
        languages: item.details.crawl_profile_languages || [""],
        skills: item.details.crawl_profile_skills
          ? item.details.crawl_profile_skills.map(
              (skill: any) => skill.skill_name
            )
          : [],
      };
      payload.push(payloadItem);
    });
    return payload;
  };

  const crawlEvaluate = async () => {
    const payload = payloadFormat();
    await axios
      .post(
        process.env.REACT_APP_BUSINESS_LOGIC_ENDPOINT + "/task/execution",
        businessPayload.script(310, { candidates: payload, jd: jobDescription })
      )
      .then((response) => {
        if (response.status == 200) {
          pusher(response.data, (message) => {
            console.log("Pusher 310: ", message);
            setLinkedinEvaluateData((prevData): any => [...prevData, message]);
          });
        }
      })
      .catch((error) => {
        notification.error("Something went wrong in server");
      });
  };

  const handleSelectCandidate = (slug: string) => {
    const sampleCandidate: TCandidate = {
      name: "",
      position: "",
      avatar: "",
      slug: "",
      overall: 0,
      skills: 0,
      experiences: 0,
      languages: 0,
      education: 0,
    };

    linkedinProfileData.map((item: any) => {
      if (item.slug == slug) {
        sampleCandidate.name = item.details.name;
        sampleCandidate.avatar = item.details.avatar;
        sampleCandidate.position = item.details.position;
        sampleCandidate.slug = item.slug;
      }
    });
    linkedinEvaluateData.map((item: any) => {
      if (item.slug == slug) {
        sampleCandidate.overall = parseInt(item.overall);
        sampleCandidate.skills = parseInt(item.skills);
        sampleCandidate.experiences = parseInt(item.experience);
        sampleCandidate.education = parseInt(item.education);
        sampleCandidate.languages = parseInt(item.languages);
      }
    });

    setSelectedCandidate(sampleCandidate);
  };

  return (
    <>
      <div
        className={`row g-3 ${visible !== true ? "d-none" : ""}`}
        style={{ height: "72.5vh" }}
      >
        <div className="col-lg-8 col-sm-12">
          <div className="table-responsive CandidateTable">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div className="candidate-select">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={""}
                      />
                    </div>
                  </th>
                  <th>Candidate</th>
                  <th>Linkedin</th>
                  <th>Overall</th>
                  <th>Experience</th>
                  <th>Skills</th>
                  <th>Languages</th>
                  <th>Education</th>
                </tr>
              </thead>
              <tbody className={linkedinUrlData.length == 0 ? "d-none" : ""}>
                {linkedinUrlData.length > 0 &&
                  linkedinUrlData.map((item: any, index: any) => {
                    var filteredProfile: any = linkedinProfileData.filter(
                      (ite: any) => ite.slug == item.slug
                    );
                    const filteredEvaluate: any = linkedinEvaluateData.filter(
                      (ite: any) => ite.slug == item.slug
                    );
                    return (
                      <tr
                        key={index}
                        onClick={(): void => handleSelectCandidate(item.slug)}
                      >
                        <td>
                          <div className="candidate-select">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={""}
                            />
                          </div>
                        </td>
                        <td>
                          {linkedinProfileData.length > 0 ? (
                            filteredProfile.length == 0 ? (
                              <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                              <div className="d-flex align-items-center gap-2">
                                <div className="flex-shrink-0">
                                  <img
                                    src={filteredProfile[0].details.avatar}
                                    width={35}
                                    className="img-fluid rounded-circle"
                                  />
                                </div>
                                <div className="flex-grow-1">
                                  <div className="data-candidate-name">
                                    {filteredProfile[0].details.name}
                                  </div>
                                  <div className="data-candidate-position">
                                    {filteredProfile[0].details.position}
                                  </div>
                                </div>
                              </div>
                            )
                          ) : (
                            <Skeleton active paragraph={{ rows: 1 }} />
                          )}
                        </td>
                        <td>
                          <a href={item.url} target="_blank">
                            <img src={LinkedInLogo} />
                          </a>
                        </td>
                        <td>
                          {linkedinEvaluateData.length > 0 ? (
                            filteredEvaluate.length == 0 ? (
                              <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                              filteredEvaluate[0].overall
                            )
                          ) : (
                            <Skeleton active paragraph={{ rows: 1 }} />
                          )}
                        </td>
                        <td>
                          {linkedinEvaluateData.length > 0 ? (
                            filteredEvaluate.length == 0 ? (
                              <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                              filteredEvaluate[0].experience
                            )
                          ) : (
                            <Skeleton active paragraph={{ rows: 1 }} />
                          )}
                        </td>
                        <td>
                          {linkedinEvaluateData.length > 0 ? (
                            filteredEvaluate.length == 0 ? (
                              <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                              filteredEvaluate[0].skills
                            )
                          ) : (
                            <Skeleton active paragraph={{ rows: 1 }} />
                          )}
                        </td>
                        <td>
                          {linkedinEvaluateData.length > 0 ? (
                            filteredEvaluate.length == 0 ? (
                              <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                              filteredEvaluate[0].languages
                            )
                          ) : (
                            <Skeleton active paragraph={{ rows: 1 }} />
                          )}
                        </td>
                        <td>
                          {linkedinEvaluateData.length > 0 ? (
                            filteredEvaluate.length == 0 ? (
                              <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                              filteredEvaluate[0].education
                            )
                          ) : (
                            <Skeleton active paragraph={{ rows: 1 }} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tbody className={linkedinUrlData.length > 0 ? "d-none" : ""}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                  (item: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                        <td>
                          <Skeleton active paragraph={{ rows: 1 }} />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
          <div className="d-flex flex-column gap-3 h-100 detail-group">
            <ScatterChart
              linkedinProfileData={linkedinProfileData}
              linkedinEvaluateData={linkedinEvaluateData}
            />
            <div className="card-data flex-fill profile-preview">
              {selectedCandidate != null || selectedCandidate != undefined ? (
                <div
                  className={`d-flex gap-2 flex-column align-items-center h-100 detail-profile`}
                >
                  <div className="d-flex justify-content-center align-items-center gap-4 w-75 mb-3">
                    <div className="flex-shrink-0">
                      <img
                        className="rounded-circle candidate-avatar"
                        src={selectedCandidate?.avatar}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div className="candidate-name">
                        {selectedCandidate.name}
                      </div>
                      <div className="candidate-position">
                        {selectedCandidate.position}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-75">
                    <div className="skills-name">Overall</div>
                    <div className="skills-point">
                      <span className="text-score">
                        {selectedCandidate.overall}
                      </span>
                      /100
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-75">
                    <div className="skills-name">Skills</div>
                    <div className="skills-point">
                      <span className="text-score">
                        {selectedCandidate.skills}
                      </span>
                      /100
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-75">
                    <div className="skills-name">Work Experiences</div>
                    <div className="skills-point">
                      <span className="text-score">
                        {selectedCandidate.experiences}
                      </span>
                      /100
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-75">
                    <div className="skills-name">Languages</div>
                    <div className="skills-point">
                      <span className="text-score">
                        {selectedCandidate.languages}
                      </span>
                      /100
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-75">
                    <div className="skills-name">Education</div>
                    <div className="skills-point">
                      <span className="text-score">
                        {selectedCandidate.education}
                      </span>
                      /100
                    </div>
                  </div>
                  <button
                    className="btn-view-detail mt-auto py-1"
                    onClick={() => {
                      setIsCandidateDetailModal(true);
                      setSelectedDetail(selectedCandidate.slug);
                    }}
                  >
                    View Detail
                  </button>
                </div>
              ) : (
                <div className="d-flex text-muted h-100 w-100 align-items-center justify-content-center detail-profile-empty">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12">
          <button className="btn-heppai btn-new-campaign float-end px-3 py-2">
            <a href="/recruiter/panel" className="w-100 h-100 text-dark">
              New Campaign
            </a>
          </button>
        </div>
      </div>
      <CandidateDetail
        isOpen={isCandidateDetailModal}
        cancel={() => setIsCandidateDetailModal(false)}
        candidateProfile={linkedinProfileData}
        candidateEvaluate={linkedinEvaluateData}
        setSelectedDetail={setSelectedDetail}
        selectedDetail={selectedDetail}
      />
    </>
  );
};

export default Result;
