import React from 'react'
import './SignIn.scss'
import BgCover from '@components/Auth/BgCover/BgCover';
import LoginForm from '@components/Auth/LoginForm/LoginForm';

const SignIn: React.FC = () => {
    return (
        <div className="bg-image bg-cover-left">
            <div className="row g-0 bg-primary-dark-op">
                <BgCover />
                <LoginForm />
            </div>
        </div>
    )
}

export default SignIn;