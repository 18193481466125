// messageHelper.ts
import { message } from "antd";

const notification = {
  success: (content: string) => {
    message.open({
      type: "success",
      content: content,
    });
  },
  error: (content: string) => {
    message.open({
      type: "error",
      content: content,
    });
  },
  warning: (content: string) => {
    message.open({
      type: "warning",
      content: content,
    });
  },
};

const businessPayload = {
  script: (script_id: number, content: any) => {
    return {
      script_id: script_id,
      backend_instance_id: 1,
      content: content,
    };
  },
};

export { notification, businessPayload };
